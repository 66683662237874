<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const { t, locale } = useI18n()
const localePath = useLocalePath()

const route = useRoute()

const query = ref(route?.query)

const queryNoPage = computed(() => _omit(query.value, ['page']))

const slug = computed(() => _get(route.params.slug ?? [], 0, ''))

const isauth = computed(() => _has(props?.data, 'isauth') ? (props?.data?.isauth ?? false) : false)

const authenticated = useAuthenticated()

const submittedText = ref(props?.data?.success_title ?? t('success'))
const thankSubmitText = ref(props?.data?.success_message ?? t('thank_submitting'))

const success = ref(false)
const paymentCode = ref('')
const paymentTotal = ref(0)
const paymentCurrency = ref()

const currency = useCookie('currency')

const currencyKey = ref(getCurrencyKey(locale.value, currency.value))

const token: any = useCookie('token')

const host = await useGetHost()

const { data: userdata }: any = await useFetch('/api/userdata', {
  headers: { token: token.value, domain: host, locale },
})

const state = computed(() => Object.assign(setColumnValue(props?.data?.columns, _isObject(userdata.value) ? userdata.value : {}), queryNoPage.value))

const payloadData: any = ref({})

const showPayMessage: any = ref(false)

async function onOk(payload: any) {
  payloadData.value = payload

  showPayMessage.value = !![100, 102].includes(payload?.payment_method)

  if (!payload?.total || [100, 102].includes(payload?.payment_method)) {
    await Promise.all([
      (success.value = true),
      (paymentCode.value = payload?.id),
      (paymentTotal.value = payload?.total),
      (paymentCurrency.value = payload?.currency),
      (currencyKey.value = getCurrencyKey(locale.value, payload?.currency)),
    ])
  }
  else if (payload?.payment_method === 101) {
    await navigateTo({ path: localePath('/checkout-balance'), query: { code: payload?.id, amount: (payload?.total ?? 0) * 1, path: slug.value, currency: payload?.currency ?? currency.value } }, { external: true })
  }
  else {
    await navigateTo({ path: localePath('/checkout'), query: { code: payload?.id, amount: (payload?.total ?? 0) * 1, path: slug.value, currency: payload?.currency ?? currency.value } }, { external: true })
  }
}
</script>

<template>
  <div class="mx-auto max-w-screen-xl">
    <div class="container m-auto px-2 text-gray-600 md:px-4 xl:px-2">
      <ArticleTitle :data="props?.data" />

      <ContentView
        v-if="props?.data?.content"
        :content="props?.data?.content"
        class="mx-auto max-w-screen-md pb-10 text-xl tracking-wide text-gray-700 article-content"
      />

      <div v-if="props?.data?.columns">
        <div v-if="authenticated || (!authenticated && !isauth)">
          <FormInput
            v-if="!success"
            :isauth="isauth || authenticated"
            :columns="props?.data?.columns"
            :state="state"
            :submit-center="true"
            :ispayment="true"
            :payment-methods="props?.data?.payment_methods"
            class="max-w-screen-md px-3"
            @ok="onOk"
          />

          <div v-else>
            <OrderSuccess
              :text1="submittedText"
              :text2="thankSubmitText"
              :code="paymentCode"
              :payment-total="paymentTotal"
              :currency-key="currencyKey"
            />

            <div v-if="showPayMessage" class="mx-auto text-center max-w-4xl px-4 py-6 sm:px-8 sm:py-10 text-xl">
              <p class="mb-10">
                {{ t('continue_pay') }}
              </p>
              <UButton
                :label="t('pay_now')"
                color="amber"
                size="xl"
                class="rounded-xl px-10 py-3 text-xl"
                @click="navigateTo({ path: localePath('/checkout'), query: { code: payloadData?.id, amount: (payloadData?.total ?? 0) * 1, path: slug, currency: payloadData?.currency ?? currency } }, { external: true })"
              >
                <template #trailing>
                  <UIcon name="i-heroicons-arrow-right-20-solid" />
                </template>
              </UButton>
            </div>
          </div>
        </div>

        <ButtonLoginToContinue v-else />
      </div>
    </div>
  </div>
</template>
